import React, {useCallback, useEffect, useState} from "react"
import style from "./style.module.scss"
import useSettings from "../../tools/settings"
import {useScreenPlugin} from "tinacms"
import {Edit as EditIcon} from "@material-ui/icons"
import {ColorButton} from "../../tools/logout";
import 'draft-js/dist/Draft.css';
import TextEditor from "../../tools/textEditor"
import {TextField, Button} from "@material-ui/core"
import {Add as AddIcon, Delete as TrashIcon} from "@material-ui/icons"
import axios from "axios"
import config from "../../config.json"

function FooterSetting(settings){
  
  const [content, setContent] = useState();  
  const [initialized, setInitialized] = useState(null)

  useEffect(()=>{
      settings.get().then(data=>{
        if(data!==undefined){
          setContent(data);
        }else{
          setContent({});            
        }
        setInitialized(false);
      }).catch(e=>{
        console.error(e)
      });
    
  },[settings, setInitialized]);

  
  const updateText = useCallback((val)=>{

     
      setContent(old=>{
          return {...old, text: val}
      })
  },[setContent])

  const updateLinks = useCallback((i, field, val)=>{
    setContent(old =>{
      let copy={...old};
      if(copy.links === undefined){
        copy.links=[];
      }
      if(copy.links[i] === undefined){
        copy.links[i]={text: "", link:""}
      }
      copy.links[i][field]=val;
      return copy;
    })
  },[setContent]);

  const addLink = useCallback(()=>{
    setContent(old =>{
      let copy={...old};
      if(copy.links === undefined){
        copy.links=[{text: "", link:""}];
      }else{
        copy.links.push({text: "", link:""})
      }
      return copy;
    })
  },[setContent]);
 
  const deleteLink = useCallback((index)=>{
    setContent(old =>{
      let copy={...old};
      copy.links.splice(index, 1)
      return copy;
    })
  },[setContent]);
 


  const submit = useCallback(()=>{
    if(content.text){
      var el = document.createElement("body")
      el.innerHTML = content.text;
      if(el.firstChild.innerHTML){
        content.text = el.firstChild.innerHTML
      }
    }
    settings.save(content);
  },[content, settings]);

  return<div className={style.settingForm}>
    {content &&
      <>
      <div className={style.group}>
        <div className={style.label}>Texte</div>       
          <TextEditor content={(content && content.text)? content.text : undefined} setContent={updateText} 
          initialized={initialized} setInitialized={setInitialized}/>   
      </div>
      {content&& 
      <div className={style.group}>
        <div className={style.label}>Liens</div>       
            {(content && content.links && Array.isArray(content.links) )?
              content.links.map((link, index)=>(
                <div  key={index}>
                  <div className={style.link}>
                    <div>
                      <TextField className={style.textField} fullWidth value={link.text} onChange={e => updateLinks(index, "text", e.target.value)} label="Texte" variant="outlined" />
                      <TextField className={style.textField} fullWidth value={link.link} onChange={e => updateLinks(index, "link", e.target.value)} label="Lien" variant="outlined" />
                    </div>
                    <Button className={style.deleteButton} size="small" onClick={()=>deleteLink(index)}>< TrashIcon /></Button>
                  </div>
                  <div className={style.spacer} />
                </div>
              ))
            :<></>//add a button here
            }
            <div className={style.row}>
              <Button onClick={addLink}><AddIcon /></Button>
            </div>
      </div>
      } 
      <div className={style.buttonRow}>
        <ColorButton onClick={submit}>Save</ColorButton>
      </div>
      </>
    }
  </div>
}

const Footer = () => {

  const settings = useSettings("footer");
  const footerPluging = {
    name: 'Paramètres du footer',
    Component: ()=> FooterSetting(settings),
    Icon: ()=><EditIcon/>,
    layout: 'popup'
  }
  useScreenPlugin(footerPluging);
   
  const [content, setContent] = useState();  
  useEffect(()=>{
      axios.get(config.api.baseurl+'/api/settings/footer').then(data=>{
        if(data!==undefined){
          setContent(data.data);
        }else{
          setContent({});            
        }
      }).catch(e=>{
        console.error(e)
      });
    
  },[]);
  
  return (
  <footer>
      <div className={style.block}>
        <div className={style.content}>
          <div className={style.links} >
            {(content && content.links && Array.isArray(content.links) ) && content.links.map((element, index)=>(
              <a
                href={element.link}
                key={index}
                className= {style.footerLink}
              >
                {element.text}
              </a>
            ))}
          </div>
          <div className={style.text}>
            {(content && content.text) && <div dangerouslySetInnerHTML={{__html : content.text}} /> }
          </div>
        </div>
        
      </div>
  </footer>
  )}

export default Footer
