import React, { useEffect } from 'react';
import Header from '../../comps/header'
import MosaiqueBiens from '../../comps/mosaique-biens'
import { checkLoggedIn } from '../../tools/settings';
import { useCMS } from 'tinacms';
import { usePlugin, useForm } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import useSettings from "../../tools/settings"
import Footer from "../../comps/footer"
import StickyContact from "../../comps/sticky-contact"
import Avenir from "../../comps/a-venir"

function Page() {
    const cms=useCMS();
    useEffect(()=>checkLoggedIn(cms),[cms]);

    const settings = useSettings(["avenir","buy"])
    // 1. Define the form
    const FormOptions = {
      id:"buy",
      loadInitialValues() {
         let data = settings.get();
         console.log(data);
         return(data)
      },
      onSubmit: (content) => settings.save(content)
    }
  
    // 2. Pass the form as the second argument
     const [, form] = useForm(FormOptions)
     // 3. Register the form as a plugin
     usePlugin(form)
    return <>
        <Header />
        <div class="page">
          <InlineForm form={form}>
                <MosaiqueBiens />
                <Avenir groupName="avenir" />
          </InlineForm>
        </div>
        <StickyContact />
        <Footer />
    </>
}

export default Page;