import React, {useState, useCallback, useEffect, useRef, useMemo} from "react"
import style from "./style.module.scss"
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {Edit as EditIcon, Close as CloseIcon, Add as AddIcon} from "@material-ui/icons"
import values from "../shared/values.json"
import background from "../../images/form_background.jpg"
import axios from "axios"
import general from "../../images/general_bleu_saumon.svg"
import config from "../../config.json"

const ImageUploader = ({image, handleImport, deleteImage}) => {

  const inputRef  = useRef();
  const transferClick = useCallback( ()=>{
    inputRef.current.click()
  },[inputRef]);
  return (
    <div className={style.imageContainer}>
      <img alt="" className={style.imageBien} src={image} />
      <div className={style.iconRow}>
        <div>
          <IconButton className={style.button} onClick={transferClick}><EditIcon /></IconButton>
          <input ref={inputRef} accept="image/*" type="file" id="imageInput" className={style.imageInput} onChange={(e)=> handleImport(e)}/> 
        </div>
        <IconButton onClick={deleteImage} ><CloseIcon/></IconButton>
      </div>
    </div>
  )
}

const DeposerForm = ({groupName}) => {

  const [formData, setFormData] = useState({});
  const [vendeurData, setVendeurData] = useState({});
  const [images, setImages] = useState([]);
  const inputRef=useRef();
  const allowedTypes = useMemo(()=>["image/png", "image/jpg, image/jpeg"],[]);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [theRef, setTheRef] = useState("");

  const transferClick = useCallback( ()=>{
    inputRef.current.click()
  },[inputRef]);

  const handleChange = useCallback((field, value)=>{
    setFormData(old => {
      let copy ={...old};
      copy[field]=value;
      return copy;
    });
  },[setFormData]);
  
  const handleVendorChange = useCallback((field, value)=>{
    setVendeurData(old => {
      let copy ={...old};
      copy[field]=value;
      return copy;
    });
  },[setVendeurData]);
 
  const handleImageChange = useCallback((index, e)=>{
    if(e && e.target && e.target.files){
      console.log(e.target.files)
      
      let img = e.target.files[0];
      if(allowedTypes.includes(img.type)){
        setImages(old =>{
          let copy=[...old];
          let val = {};
          val.obj = new Blob([img], {type: img.type});
          val.url = URL.createObjectURL(val.obj);
          copy[index] = val;
          return copy;
        });
        e.target.value= null;
      }
      
    }
  }, [setImages, allowedTypes]);

  const deleteImage = useCallback( (index)=>{
    setImages(old =>{
      let copy=[...old];
      copy.splice(index,1);
      return copy;
    });
  }, [setImages]);

  const handleClose = useCallback(()=>{
    if(confirm || progress>=40){
        setFormData({});
        setVendeurData({});
        setImages([]);
    }
    setProgress(0);
    setError(false);
    setConfirm(false);
  },[confirm, progress]);
  
  const handleSubmit = useCallback(()=>{
    setLoading(true);
    console.log(formData);
    axios({
        method: 'post',
        url: config.api.baseurl+'/api/create-bien',
        data: formData
    }).then((response)=>{
        setProgress(15);
        let data=response.data;
        let reference=data.reference;
        setTheRef(reference);
        let idbien=data.id;
        axios({
            method: 'post',
            url: config.api.baseurl+'/api/create-vendeur',
            data:vendeurData
        }).then((response)=>{
            let idvendeur=response.data.id
            setProgress(30);
            axios({
            method : 'put',
            url: config.api.baseurl+'/api/bien_update_vendeur/'+idbien+'/'+idvendeur,
            data: {'id': idbien, 'vendeur': vendeurData}
            }).then((response) => {
                setProgress(40);
                let nbimg = 0;
                images.forEach( item => {
                    var name = "img"+reference+"-"+String(nbimg+1)+"."+item.obj.type.split("/")[1];
                    var bodyFormData = new FormData();
                    bodyFormData.append('photo', item.obj, name);
                    bodyFormData.append('position', nbimg);
                    bodyFormData.append('bien_associe', idbien);
                    axios({
                        method : 'post',
                        url: config.api.baseurl+'/api/photo-create',
                        data: bodyFormData
                    }).then(response => {
                        setProgress(old => old+20);
                        nbimg++;
                        if(nbimg===images.length){
                            setLoading(false);
                            setConfirm(true);
                            setProgress(100);
                        }
                    }).catch(e => {
                        console.log(e);
                        setLoading(false);
                        setError(true);
                    });
                });
                if(images.length===0){
                    setLoading(false);
                    setProgress(100);
                    setConfirm(true);
                }
            }).catch(e => {
                console.log(e);
                setLoading(false);
                setError(true);
            })
            
        }).catch((e)=>{
                console.log(e);
                setLoading(false);
                setError(true);
        }).catch((e)=>{
                console.log(e);
                setLoading(false);
                setError(true);
        });
    });
  },[formData, images, vendeurData])
  useEffect( () => console.log(images), [images])
    return (
      <div className={style.block}>
        <img src={background} className={style.background} alt="" />  
        {loading&& 
        <div className={style.loader}>
            <CircularProgress variant="determinate" value={progress} />
        </div>} 
          <div className={style.content}>
            <Dialog
                open={confirm}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <div className={style.dialogContent}>
                        <div className={style.col}>
                            <img src={general} alt="" className={style.img}/>
                        </div>
                        <div className={style.col}>
                            <p>Votre annonce a bien été enregistrée.</p>
                            <p className={style.subtitle}>Votre référence d'annonce : <span className={style.ref}>{theRef}</span></p>
                            <p>Merci de bien noter votre référence.<br/>
                            Un conseiller General Valor prendra contact avec vous très prochainement pour finaliser votre annonce.</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Valider</Button>
                    <Button href="/" color="secondary">Retour à l'accueil</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={error}
                onClose={handleClose}
            >
              <DialogTitle>OUPS</DialogTitle>
                <DialogContent>
                    <div className={style.dialogContent}>
                        <div className={style.col}>
                            <img src={general} alt="" className={style.img}/>
                        </div>
                        <div className={style.col}>
                            <p>Une erreur s'est produite lors de l'envoi de votre annonce, veuillez recharger la page et réessayer</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>window.location.reload()} color="primary">Recharger</Button>
                    <Button href="/" color="secondary">Retour à l'accueil</Button>
                </DialogActions>
            </Dialog>
            
            <h3 className={style.bold}>Déposez votre annonce maintenant</h3>
          
            <h6 className={style.subtitle}>Gratuitement </h6>
            <p className={style.topText}>
              Veuillez remplir le formulaire ci-dessous pour soumettre votre bien à une estimation par nos conseillers puis déposer votre annonce. Toutes les informations sont essentielles pour estimer votre bien à sa juste valeur et accélérer la vente.
            </p>
            <form onSubmit={handleSubmit}>
              <h5>Localisation</h5>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField required className={style.input} fullWidth label="Ville" value={formData.ville || ""} onChange={(e)=>handleChange("ville", e.target.value)} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField required type="number" className={style.input} fullWidth label="Code Postal" value={formData.code_postal || ""} onChange={(e)=>handleChange("code_postal", e.target.value)} /> 
                </Grid>
                <Grid item xs={6} md={1}>
                  <TextField required type="number" className={style.input} fullWidth label="N°" value={formData.numero_rue || ""} onChange={(e)=>handleChange("numero_rue", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField required className={style.input} fullWidth label="Rue" value={formData.rue || ""} onChange={(e)=>handleChange("rue", e.target.value)} /> 
                </Grid>
              </Grid>
              <h5>Description</h5>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl required  fullWidth>
                    <InputLabel id="label_type">Type de bien</InputLabel>
                    <Select labelId="label_type" className={style.input} fullWidth label="Type de bien" value={formData.type_bien || ""} onChange={(e)=>handleChange("type_bien", e.target.value)}>
                    {values.types_biens && values.types_biens.map((type, i) => (
                      <MenuItem key={i} value={type}>{type}</MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={style.checkbox}
                    control={
                      <Checkbox
                        checked={formData.en_copro || false}
                        onChange={(e)=>handleChange("en_copro", e.target.checked)}
                        color="secondary"
                      />
                    }
                    label="En copropriété"
                  /> 
                </Grid>
                {formData.en_copro &&
                <Grid item xs={12}>
                  <Grid container spacing={3}> 
                    <Grid item xs={12}>
                      <Alert className={style.alert} severity="warning" variant="filled">
                      Pour les biens en copropriété, vous êtes légalement tenus de préciser le nombre de lots, les charges de copropriété, le type de syndicat, ainsi que si le syndicat est actuellement visé par une procédure.
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField required className={style.input} type="number" fullWidth label="Nombre de lots" value={formData.nombre_lots_copro || ""} onChange={(e)=>handleChange("nombre_lots_copro", e.target.value)} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField required className={style.input} type="number" fullWidth label="Charges de copropriété" value={formData.charges_copro_mens || ""} onChange={(e)=>handleChange("charges_copro_mens", e.target.value)} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl required fullWidth>
                        <InputLabel id="label_syndic">Syndicat</InputLabel>
                        <Select labelId="label_syndic" className={style.input} fullWidth value={formData.syndicat || ""} onChange={(e)=>handleChange("syndicat", e.target.value)}>
                        {values.types_syndics && values.types_syndics.map((type, i) => (
                          <MenuItem key={i} value={type}>{type}</MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl required fullWidth>
                        <InputLabel id="label_syndic">Procédure</InputLabel>
                        <Select labelId="label_syndic" className={style.input} fullWidth value={formData.procedures || ""} onChange={(e)=>handleChange("procedures", e.target.value)}>
                        {values.listeProcedures && values.listeProcedures.map((type, i) => (
                          <MenuItem key={i} value={type}>{type}</MenuItem>
                        ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={12} sm={6}>
                  <TextField required className={style.input} type="number" fullWidth label="Surface" value={formData.surface_logement || ""} onChange={(e)=>handleChange("surface_logement", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField required className={style.input} type="number" fullWidth label="Nb. de pièces" value={formData.nombre_piece || ""} onChange={(e)=>handleChange("nombre_piece", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField required className={style.input} type="number" fullWidth label="Nb. de chambres" value={formData.nombre_chambres || ""} onChange={(e)=>handleChange("nombre_chambres", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField required className={style.input} type="number" fullWidth label="Nb. de salles de bain" value={formData.nb_salle_bain || ""} onChange={(e)=>handleChange("nb_salle_bain", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField required className={style.input} type="number" fullWidth label="Balcons" value={formData.nombre_balcon || ""} onChange={(e)=>handleChange("nombre_balcon", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={style.checkbox}
                    control={
                      <Checkbox
                        checked={formData.terrasse || false}
                        onChange={(e)=>handleChange("terrasse", e.target.checked)}
                        color="secondary"
                      />
                    }
                    label="Terrasse"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={style.checkbox}
                    control={
                      <Checkbox
                        checked={formData.jardin || false}
                        onChange={(e)=>handleChange("jardin", e.target.checked)}
                        color="secondary"
                      />
                    }
                    label="Jardin"
                  /> 
                </Grid>
                {formData.type_bien && formData.type_bien==="Appartement" &&
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      className={style.checkbox || false}
                      control={
                        <Checkbox
                          checked={formData.ascenseur}
                          onChange={(e)=>handleChange("ascenseur", e.target.checked)}
                          color="secondary"
                        />
                      }
                      label="Ascenseur"
                    /> 
                  </Grid>
                </>
                }
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    className={style.checkbox}
                    control={
                      <Checkbox
                        checked={formData.cave || false}
                        onChange={(e)=>handleChange("cave", e.target.checked)}
                        color="secondary"
                      />
                    }
                    label="Cave"
                  /> 
                </Grid>
              </Grid>
              <h5>Diagnostique Énergétique</h5>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField required className={style.input} type="number" fullWidth label="Valeur énergétique" value={formData.valeur_energetique || ""} onChange={(e)=>handleChange("valeur_energetique", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField required className={style.input} type="number" fullWidth label="Valeur GES" value={formData.valeur_ges || ""} onChange={(e)=>handleChange("valeur_ges", e.target.value)} /> 
                </Grid>
              </Grid>
              <h5>Contenu de l'annonce</h5>
              <p>Choisissez jusqu'à 3 photos (format JPG), et rédigez une courte description de votre bien.</p>
              <Grid container spacing={3}>
                {images.map((element, i)=>(
                   <Grid key={i} item xs={12} md={4}>
                      <ImageUploader deleteImage={()=>deleteImage(i)} image={element.url} handleImport={(val)=>handleImageChange(i, val)} />
                    </Grid>
                ))}
                {images.length < 3 &&
                  <Grid  item xs={12} md={4}>
                    <div  className={style.addButton}>
                      <Button className={style.button} variant="contained" onClick={transferClick}><AddIcon/></Button>
                      <input accept={allowedTypes} type="file" ref={inputRef} className={style.imageInput} onChange={(e)=> handleImageChange(images.length, e)}/> 
                    </div>
                  </Grid>
                }
                <Grid item xs={12}>
                  <TextField required multiline className={style.input} fullWidth label="Description" value={formData.description || ""} onChange={(e)=>handleChange("description", e.target.value)} /> 
                </Grid>          
              </Grid>
              
              <h5>Coordonnées</h5>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField required className={style.input} fullWidth label="Nom" value={vendeurData.nom || ""} onChange={(e)=>handleVendorChange("nom", e.target.value)} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField required className={style.input} fullWidth label="Prenom" value={vendeurData.prenom || ""} onChange={(e)=>handleVendorChange("prenom", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField required type="number" className={style.input} fullWidth label="Téléphone" value={vendeurData.tel || ""} onChange={(e)=>handleVendorChange("tel", e.target.value)} /> 
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField required type="email" className={style.input} fullWidth label="E-mail" value={vendeurData.mail || ""} onChange={(e)=>handleVendorChange("mail", e.target.value)} /> 
                </Grid>

                <Grid item xs={12}>
                  <p>Comment nous avez-vous connu ?</p>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl required fullWidth>
                    <InputLabel id="label_origine">Internet, Bouche à oreille,..</InputLabel>
                    <Select labelId="label_origine" className={style.input} fullWidth value={vendeurData.origine || ""} onChange={(e)=>handleVendorChange("origine", e.target.value)}>
                    {values.origines && values.origines.map((type, i) => (
                      <MenuItem key={i} value={type}>{type}</MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                  <p className={style.smallText}>General Valor traite les données recueillies à des fins de prestation commerciales.
                  Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à nos <a href="/mentions-legales">mentions légales</a>.</p>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={style.checkbox}
                  control={
                    <Checkbox
                      required
                      color="secondary"
                    />
                  }
                  label={<span className={style.smallLabel}>J'approuve l'utilisation de mes données telle que décrite dans les <a href="/mentions-legales">mentions légales</a></span>}
                /> 
              </Grid>
              <div className={style.submitContainer}>
                <Button type="submit" className={style.button} variant="contained" size="large">Déposer mon annonce</Button>
              </div>
            </form>
          </div>
      </div>
    )
}

export default DeposerForm;