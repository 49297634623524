import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import useRouter from "./router";
import { TinaProvider, TinaCMS } from 'tinacms';
import DjangoMediaStore from "./tools/media-store"
import LogoutWidget from "./tools/logout";
import "./App.css"
import { createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';

export default function App() {
  const router=useRouter();
  const cms = new TinaCMS({
    toolbar: true,
    enabled: false,
    media: new DjangoMediaStore(),
    plugins:[LogoutWidget]
  });
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1F2142'
      },
      secondary: {
        main: '#EB826C'
      },
      
    },
  });
  

  return (
    <TinaProvider cms={cms}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <Switch>
              {router.map((e, index)=>(
                <Route key={index} path={e.path} exact={e.exact}>
                  {e.component}
                </Route>
              ))}
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    </TinaProvider>
  );
}