import React from "react"
import { InlineGroup, InlineTextarea, InlineImage } from 'react-tinacms-inline'
import style from "./style.module.scss"
import partnersBackground from "../../images/partners_background.png"

const Partners = ({groupName})=>{

   return (
    <InlineGroup name={groupName}>
      <div className={style.block}>
        <div className={style.background}>
          <img src={partnersBackground} alt=""/>
        </div>

          <div className={style.content}>
            <div className={style.top}>
              <h3 className={style.title}><InlineTextarea name='title' /></h3>
              <h6 className={style.title}><InlineTextarea name='subtitle' /></h6>
            </div>
          </div>

            
            <div className={style.partnersContent}>

              <div className={style.overlay}>
                <div className={style.container}>
                <div className={style.partners}>
                  <InlineImage name="partner1"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner2"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner3"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner4"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner5"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner6"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner7"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner8"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner9"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner10"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner11"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  <InlineImage name="partner12"
                    parse={media =>{console.log(media); return media.filename}}
                    uploadDir={""}
                    alt=""
                  />
                  {/*content.frontmatter.partners.map((img, index)=>(
                    img.image!==""&&
                    //<Img fluid={ data.partnersImages.nodes.find(e => e.relativePath==="partners/images/"+img.image).childImageSharp.fluid} className={style.partner} key={index}/>
                  ))*/}
                  </div>
                  </div>
                </div>
            </div>
       </div>
       </InlineGroup>
   )

}

export default Partners

