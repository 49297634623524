import React from "react"
import style from "./style.module.scss"
import { InlineGroup, InlineTextarea } from 'react-tinacms-inline'
import { InlineWysiwyg } from 'react-tinacms-editor'
import ReactMarkdown from 'react-markdown'

const PageHero = ({groupName, backgroundImage, content})=>{

  return (
    <InlineGroup name={groupName}>
      <div className={style.block}>
        <img src={backgroundImage} className={style.image} alt="" />
        <div className={style.content}>
            <h3 className={style.title}><InlineTextarea style={{minHeight:"50px", minWidth:"50px", backgroundColor: "red"}} name='title' /></h3>
            <p className={style.text}>
                <InlineWysiwyg name="text" format="markdown">
                <ReactMarkdown source={(content && content[groupName] && content[groupName]["text"])?content[groupName]["text"] : null } />
                </InlineWysiwyg>
            </p>
        </div>
      </div>
    </InlineGroup>
  )
}

export default PageHero