import React from "react"
import style from "./style.module.scss"
import {
    Card, 
    CardActionArea, 
    CardMedia,
    CardContent
}  from "@material-ui/core"
import { InlineGroup, InlineImage, InlineText } from "react-tinacms-inline"


const TileBien = ({name, className=style.tile}) => {
    return <>
    <Card className={className} elevation={0} >
      <CardActionArea>
        <CardMedia
          image={null}
          title="bien"
          className={style.tileImg}
        >
            <InlineImage name={name}
                    parse={media =>{return media.filename}}
                    uploadDir={""}
                    alt=""
            />
        </CardMedia>
        <CardContent className={style.infos}>
            <h5><InlineText className={style.name}  name={name+"-ville"} /></h5>
            <div><InlineText className={style.name}  name={name+"-infos"} /></div>
        </CardContent>
      </CardActionArea>
    </Card>
    </>
}

const MosaiqueBiens = ({groupName}) => {


    return (
        <InlineGroup name={groupName}>
        <div className={style.block}>
        <div className={style.content}>
            <h5 className={style.blockTitle}>À venir :</h5>
            <div className={style.grid}>
                <div className={style.col}>
                    <TileBien className={style.tileBlue} name="a-venir1" />
                </div> 
                <div className={style.col}>
                    <TileBien name="a-venir2" />
                </div> 
                <div className={style.col}>
                    <TileBien className={style.tileYellow} name="a-venir3" />  
                </div> 
                <div className={style.col}>
                    <TileBien className={style.tileGreen} name="a-venir4" />
                </div> 
            </div>

        </div>
        
        </div>
        </InlineGroup>
    )}

export default MosaiqueBiens