import React, { useState, useCallback, useEffect } from 'react';
import style from './style.module.scss';
import {
  Button,
  Dialog,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import axios from 'axios';
import useMedia from 'use-media';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Circle } from 'react-leaflet';
import config from '../../config.json';

const LeafletMap = ({ element }) => {
  return (
    <>
      {element && element.lon && element.lat && (
        <Map
          className={style.map}
          center={[Number(element.lon) + 0.004, Number(element.lat) + 0.004]}
          zoom={14}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Circle
            center={[Number(element.lon) + 0.004, Number(element.lat) + 0.004]}
            radius={1000}
          />
        </Map>
      )}{' '}
    </>
  );
};

const DialogBien = ({ element, open, handleClose }) => {
  return (
    <Dialog
      className={style.dialog}
      scroll="body"
      open={open}
      maxWidth="xl"
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle className={style.head}>
        <div className={style.headContent}>
          <div className={style.headRow}>
            <span className={style.left}>
              <h5>
                {element.type_bien}{' '}
                {element.type_bien === 'Appartement' ||
                element.type_bien === 'Maison'
                  ? ' - ' +
                    element.surface_logement +
                    'm² ' +
                    (element.nombre_piece && element.nombre_piece > 0
                      ? '- ' + element.nombre_piece + ' pièces '
                      : '')
                  : ' ' + element.surface_terrain + 'm² '}
              </h5>
              <h5 className={style.light}>{' Ref : ' + element.reference}</h5>
            </span>
            <h5>{element.prix}€</h5>
          </div>
          <IconButton className={style.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={style.dialogBody}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card elevation={4}>
              <Carousel className={style.slider}>
                {element.photos.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image.photo}
                      alt="bien"
                      className={style.sliderImage}
                    />
                  </div>
                ))}
              </Carousel>
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Description</h6>
                  <p>{element.description}</p>
                  {element.visite_virtuelle && (
                    <TextField
                      label="visite virtuelle"
                      fullWidth
                      value={element.visite_virtuelle || ''}
                      disabled
                    />
                  )}
                </CardContent>
              </Card>
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Général</h6>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Type de bien"
                        fullWidth
                        value={element.type_bien || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="En Copropriété"
                        fullWidth
                        value={element.en_copro ? 'oui' : 'non'}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card elevation={4}>
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Aspect financier</h6>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Prix de vente"
                        fullWidth
                        value={element.prix ? element.prix + '€' : ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Taxe foncière"
                        fullWidth
                        value={
                          element.taxe_fonciere
                            ? element.taxe_fonciere + '€'
                            : ''
                        }
                        disabled
                      />
                    </Grid>
                    {element.en_copro && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Charges de copropriété"
                          fullWidth
                          value={
                            element.charges_copro_mens
                              ? element.charges_copro_mens + '€/mois'
                              : ''
                          }
                          disabled
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {element.en_copro && (
                <Card elevation={0}>
                  <CardContent className={style.dialogCard}>
                    <h6>Copropriété</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Procédures en cours"
                          fullWidth
                          value={element.procedures || ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Syndicat"
                          fullWidth
                          value={element.syndicat || ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Nombre de lots en copropriété"
                          fullWidth
                          value={element.nombre_lots_copro || ''}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Dont lots habitation"
                          fullWidth
                          value={element.nombre_lots_habitation || ''}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
              {element.type_bien !== 'Appartement' && (
                <Card elevation={0}>
                  <CardContent className={style.dialogCard}>
                    <h6>Extérieur</h6>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Surface terrain"
                          fullWidth
                          value={
                            element.surface_terrain
                              ? element.surface_terrain + 'm²'
                              : ''
                          }
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Jardin"
                          fullWidth
                          value={element.jardin ? 'Oui' : 'Non'}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Intérieur</h6>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Surface"
                        fullWidth
                        value={
                          element.surface_logement
                            ? element.surface_logement + 'm²'
                            : ''
                        }
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Mode de chauffage"
                        fullWidth
                        value={element.mode_chauffage || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Type de chauffage"
                        fullWidth
                        value={element.type_chauffage || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Type de cuisine"
                        fullWidth
                        value={element.cuisine || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de pièces"
                        fullWidth
                        value={element.nombre_piece || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de chambres"
                        fullWidth
                        value={element.nombre_chambres || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de salles de bain"
                        fullWidth
                        value={element.nb_salle_bain || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de salles d'eau"
                        fullWidth
                        value={element.nb_salle_eau || ''}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Autres</h6>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Ascenseur"
                        fullWidth
                        value={element.ascenseur ? 'Oui' : 'Non'}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Cave"
                        fullWidth
                        value={element.cave ? 'Oui' : 'Non'}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Grenier"
                        fullWidth
                        value={element.grenier ? 'Oui' : 'Non'}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Servitude"
                        fullWidth
                        value={element.servitude ? 'Oui' : 'Non'}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de balcons"
                        fullWidth
                        value={element.nombre_balcon || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de stationnements"
                        fullWidth
                        value={element.nombre_stationnement || ''}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de salles de bain"
                        fullWidth
                        value={element.nb_salle_bain}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Nombre de salles d'eau"
                        fullWidth
                        value={element.nb_salle_eau}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card elevation={4}>
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Localisation</h6>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <LeafletMap element={element} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card elevation={0}>
                <CardContent className={style.dialogCard}>
                  <h6>Diagnostique énergétique</h6>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      xl={6}
                      className={style.diagnosticEnergetique}
                    >
                      <h6 className={style.diagnosticHeader}>
                        Classe énergétique
                      </h6>
                      <div className={style.diagnostic}>
                        <div className={style.a}>
                          <span>≤70</span>
                          <span>A</span>
                        </div>
                        {element.valeur_energetique <= 70 && (
                          <div className={style.valeurEnergetique}>
                            {element.valeur_energetique}
                          </div>
                        )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.b}>
                          <span>11 à 110</span>
                          <span>B</span>
                        </div>
                        {element.valeur_energetique <= 110 &&
                          element.valeur_energetique > 70 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_energetique}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.c}>
                          <span>111 à 180</span>
                          <span>C</span>
                        </div>
                        {element.valeur_energetique <= 180 &&
                          element.valeur_energetique > 110 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_energetique}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.d}>
                          <span>181 à 250</span>
                          <span>D</span>
                        </div>
                        {element.valeur_energetique <= 250 &&
                          element.valeur_energetique > 180 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_energetique}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.e}>
                          <span>251 à 330</span>
                          <span>E</span>
                        </div>
                        {element.valeur_energetique <= 330 &&
                          element.valeur_energetique > 250 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_energetique}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.f}>
                          <span>331 à 420</span>
                          <span>F</span>
                        </div>
                        {element.valeur_energetique <= 420 &&
                          element.valeur_energetique > 330 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_energetique}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.g}>
                          <span>≥421</span>
                          <span>G</span>
                        </div>
                        {element.valeur_energetique > 420 && (
                          <div className={style.valeurEnergetique}>
                            {element.valeur_energetique}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} xl={6} className={style.diagnosticGES}>
                      <h6 className={style.diagnosticHeader}>Classe GES</h6>
                      <div className={style.diagnostic}>
                        <div className={style.a}>
                          <span>≤6</span>
                          <span>A</span>
                        </div>
                        {element.valeur_ges <= 6 && (
                          <div className={style.valeurEnergetique}>
                            {element.valeur_ges}
                          </div>
                        )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.b}>
                          <span>7 à 11</span>
                          <span>B</span>
                        </div>
                        {element.valeur_ges <= 11 && element.valeur_ges > 6 && (
                          <div className={style.valeurEnergetique}>
                            {element.valeur_ges}
                          </div>
                        )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.c}>
                          <span>12 à 30</span>
                          <span>C</span>
                        </div>
                        {element.valeur_ges <= 30 &&
                          element.valeur_ges > 11 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_ges}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.d}>
                          <span>31 à 50</span>
                          <span>D</span>
                        </div>
                        {element.valeur_ges <= 50 &&
                          element.valeur_ges > 30 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_ges}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.e}>
                          <span>51 à 70</span>
                          <span>E</span>
                        </div>
                        {element.valeur_ges <= 70 &&
                          element.valeur_ges > 50 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_ges}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.f}>
                          <span>71 à 100</span>
                          <span>F</span>
                        </div>
                        {element.valeur_ges <= 100 &&
                          element.valeur_ges > 70 && (
                            <div className={style.valeurEnergetique}>
                              {element.valeur_ges}
                            </div>
                          )}
                      </div>
                      <div className={style.diagnostic}>
                        <div className={style.g}>
                          <span>101</span>
                          <span>G</span>
                        </div>
                        {element.valeur_ges > 100 && (
                          <div className={style.valeurEnergetique}>
                            {element.valeur_ges}
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
const TileBien = ({ element, handleClick }) => {
  return (
    <>
      <Card className={style.tile} elevation={0}>
        <CardActionArea onClick={handleClick}>
          <CardMedia image={null} title="bien" className={style.tileImg}>
            <div className={style.imageContainer}>
              {element.photos &&
                element.photos.length > 0 &&
                element.photos[0].photo && (
                  /*<LazyLoadImage
              alt="bien"
              height="200px"
              widht="100%"
              className={style.tileTrueImg}
              placeholder={<CircularProgress color="secondary" />}
              src={element.photos[0].photo}
            />*/
                  <img
                    alt="bien"
                    src={element.photos[0].photo}
                    className={style.tileTrueImg}
                  />
                )}
            </div>
          </CardMedia>
          <CardContent className={style.infos}>
            <div className={style.details}>
              <h5>{element.ville}</h5>
              <div>
                {element.type_bien}
                {element.type_bien === 'Appartement' ||
                element.type_bien === 'Maison'
                  ? ' ' + element.surface_logement + 'm²'
                  : ' ' + element.surface_terrain + 'm²'}
                <br />
                {(element.type_bien === 'Appartement' ||
                  element.type_bien === 'Maison') && (
                  <>
                    {element.nombre_piece +
                      ' pièce' +
                      (element.nombre_piece > 1 ? 's' : '')}
                    <br />
                  </>
                )}
                {element.prix + '€'}
              </div>
              <div className={style.more}>
                {(element.type_bien === 'Appartement' ||
                  element.type_bien === 'Maison') &&
                  element.nombre_chambres > 0 && (
                    <>
                      {element.nombre_chambres +
                        ' chambre' +
                        (element.nombre_chambres > 1 ? 's' : '')}
                      <br />
                    </>
                  )}

                {element.type_bien === 'Maison' &&
                  element.surface_terrain &&
                  element.surface_terrain > 0 && (
                    <>{element.surface_terrain + 'm² de terrain'}</>
                  )}
              </div>

              <div className={style.description}>
                <p className={style.descriptionContent}>{element.abstract}</p>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
            className={style.showMore}
          >
            En savoir plus
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

const MosaiqueBiens = ({ filter }) => {
  const [biens, setBiens] = useState([]);
  const [selectedBien, setSelectedBien] = useState();
  const [open, setOpen] = useState(false);

  const handleTileClick = useCallback(
    (bien) => {
      setSelectedBien(bien);
      setOpen((old) => !old);
    },
    [setSelectedBien, setOpen]
  );

  useEffect(() => {
    axios
      .get(config.api.baseurl + '/api/mandats')
      .then((response) => {
        if (response.data) {
          let mandats = response.data;
          //mettre les prix en formes
          mandats.forEach((element) => {
            let prix = element.prix
              .toString()
              .split('')
              .reverse()
              .join('')
              .match(/.{0,3}/g)
              .join(' ')
              .split('')
              .reverse()
              .join('');
            element.prix = prix;
            element.abstract =
              element.description.split(' ').slice(0, 30).join(' ') + '...';
          });
          setBiens(mandats);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setBiens]);

  const isWide = useMedia({ minWidth: style.tabletBreakpoint });
  const isMobile = useMedia({ maxWidth: style.mobileBreakpoint });

  return (
    <div className={style.block}>
      <div className={style.content}>
        <h5 className={style.blockTitle}>En ce moment :</h5>
        {selectedBien && (
          <DialogBien
            element={selectedBien}
            open={open}
            handleClose={() => setOpen(false)}
          />
        )}
        <div className={style.grid}>
          {isMobile && (
            <div className={style.col}>
              {biens.map((element, index) => (
                <TileBien
                  handleClick={() => handleTileClick(element)}
                  element={element}
                  key={index}
                />
              ))}
            </div>
          )}
          {!isMobile && !isWide && (
            <>
              <div className={style.col}>
                {biens
                  .filter((element, index) => index % 2 === 0)
                  .map((element, index) => (
                    <TileBien
                      handleClick={() => handleTileClick(element)}
                      key={index}
                      element={element}
                    />
                  ))}
              </div>
              <div className={style.col}>
                {biens
                  .filter((element, index) => index % 2 === 1)
                  .map((element, index) => (
                    <TileBien
                      handleClick={() => handleTileClick(element)}
                      key={index}
                      element={element}
                    />
                  ))}
              </div>
            </>
          )}
          {isWide && (
            <>
              <div className={style.col}>
                {biens
                  .filter((element, index) => index % 3 === 0)
                  .map((element, index) => (
                    <TileBien
                      handleClick={() => handleTileClick(element)}
                      key={index}
                      element={element}
                    />
                  ))}
              </div>
              <div className={style.col}>
                {biens
                  .filter((element, index) => index % 3 === 1)
                  .map((element, index) => (
                    <TileBien
                      handleClick={() => handleTileClick(element)}
                      key={index}
                      element={element}
                    />
                  ))}
              </div>
              <div className={style.col}>
                {biens
                  .filter((element, index) => index % 3 === 2)
                  .map((element, index) => (
                    <TileBien
                      handleClick={() => handleTileClick(element)}
                      key={index}
                      element={element}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MosaiqueBiens;
