import React, { useEffect } from 'react';
import PageHero from '../../comps/page-hero';
import Header from '../../comps/header'
import { checkLoggedIn } from '../../tools/settings';
import { useCMS } from 'tinacms';
import { usePlugin, useForm } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import useSettings from "../../tools/settings"
import Footer from "../../comps/footer"
import StickyContact from "../../comps/sticky-contact"
import contact from "../../images/Contact.jpg"
import WysiwigBlock from "../../comps/wysiwyg"

function Page() {
    const cms=useCMS();
    useEffect(()=>checkLoggedIn(cms),[cms]);

    const settings = useSettings(["aboutHero", "aboutContent"])
    // 1. Define the form
    const FormOptions = {
      id:"about",
      loadInitialValues() {
         let data = settings.get();
         console.log(data);
         return(data)
      },
      onSubmit: (content) => settings.save(content)
    }
  
    // 2. Pass the form as the second argument
     const [content, form] = useForm(FormOptions)
     // 3. Register the form as a plugin
     usePlugin(form)
    return <>
        <Header />
        <InlineForm form={form}>
        <div class="page">
          <PageHero content={content} groupName="aboutHero" backgroundImage={contact}/>
          <WysiwigBlock content={content} groupName="aboutContent" />
        </div>
        </InlineForm>
        <StickyContact />
        <Footer />


    </>
}

export default Page;