import React, { useState, useEffect, useCallback, useRef } from 'react';
import style from './style.module.scss';
import { InlineGroup, InlineTextarea } from 'react-tinacms-inline';
import { TextField, Button, Slider } from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as TrashIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { ColorButton } from '../../tools/logout';
import useSettings from '../../tools/settings';
import { useScreenPlugin } from 'tinacms';

function PriceSettings(settings) {
  const [content, setContent] = useState();

  useEffect(() => {
    settings
      .get()
      .then((data) => {
        if (data !== undefined) {
          setContent(data);
        } else {
          setContent({});
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [settings]);

  const updateAverageRate = useCallback(
    (val) => {
      setContent((old) => {
        let copy = { ...old };
        copy.averageRate = val;
        return copy;
      });
    },
    [setContent]
  );

  const updateAverageRateText = useCallback(
    (val) => {
      setContent((old) => {
        let copy = { ...old };
        copy.averageRateText = val;
        return copy;
      });
    },
    [setContent]
  );

  const updateRate = useCallback(
    (i, field, val) => {
      setContent((old) => {
        let copy = { ...old };
        if (copy.links === undefined) {
          copy.links = [];
        }
        if (copy.links[i] === undefined) {
          copy.links[i] = { val: '', rate: '' };
        }
        copy.links[i][field] = val;
        return copy;
      });
    },
    [setContent]
  );

  const addRate = useCallback(() => {
    setContent((old) => {
      let copy = { ...old };
      if (copy.links === undefined) {
        copy.links = [{ val: '', rate: '' }];
      } else {
        copy.links.push({ val: '', rate: '' });
      }
      return copy;
    });
  }, [setContent]);

  const deleteRate = useCallback(
    (index) => {
      setContent((old) => {
        let copy = { ...old };
        copy.links.splice(index, 1);
        return copy;
      });
    },
    [setContent]
  );

  const submit = useCallback(() => {
    settings.save(content);
  }, [content, settings]);

  return (
    <div className={style.settingForm}>
      {content && (
        <>
          <div className={style.group}>
            <div className={style.label}>Taux moyen français</div>
            <div className={style.link}>
              <div>
                <TextField
                  type="number"
                  inputProps={{ step: 0.01, min: 0 }}
                  className={style.textField}
                  fullWidth
                  value={content.averageRate ? content.averageRate : 0}
                  onChange={(e) => updateAverageRate(e.target.value)}
                  label="Taux moyen (%)"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={style.link}>
              <div>
                <TextField
                  type="text"
                  multiline
                  className={style.textField}
                  fullWidth
                  value={content.averageRateText ? content.averageRateText : ''}
                  onChange={(e) => updateAverageRateText(e.target.value)}
                  label="Text légal"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div className={style.group}>
            <div className={style.label}>Paliers et Taux</div>
            {
              content && content.links && Array.isArray(content.links) ? (
                content.links.map((link, index) => (
                  <div key={index}>
                    <div className={style.link}>
                      <div>
                        <TextField
                          type="number"
                          inputProps={{ step: 1000, min: 0 }}
                          className={style.textField}
                          fullWidth
                          value={link.val}
                          onChange={(e) =>
                            updateRate(index, 'val', e.target.value)
                          }
                          label="Pailer (€)"
                          variant="outlined"
                        />
                        <TextField
                          type="number"
                          inputProps={{ step: 0.01, min: 0 }}
                          className={style.textField}
                          fullWidth
                          value={link.rate}
                          onChange={(e) =>
                            updateRate(index, 'rate', e.target.value)
                          }
                          label="Taux (%)"
                          variant="outlined"
                        />
                      </div>
                      <Button
                        className={style.deleteButton}
                        size="small"
                        onClick={() => deleteRate(index)}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                    <div className={style.spacer} />
                  </div>
                ))
              ) : (
                <></>
              ) //add a button here
            }
            <div className={style.row}>
              <Button onClick={addRate}>
                <AddIcon />
              </Button>
            </div>
          </div>
          <div className={style.buttonRow}>
            <ColorButton onClick={submit}>Save</ColorButton>
          </div>
        </>
      )}
    </div>
  );
}

const Prices = ({ groupName, content }) => {
  const settings = useSettings('prices');
  const pricesPluging = {
    name: 'Gestion des taux',
    Component: () => PriceSettings(settings),
    Icon: () => <EditIcon />,
    layout: 'popup',
  };
  useScreenPlugin(pricesPluging);

  const [marks, setMarks] = useState([]);
  const [sliderVal, setSliderVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [tooltipProp, setTooltipProp] = useState(null);
  const [saving, setSaving] = useState(0);
  let timer = useRef();

  useEffect(() => {
    if (
      content &&
      groupName &&
      content &&
      content[groupName] &&
      content[groupName]['links'] &&
      Array.isArray(content[groupName]['links'])
    ) {
      let arr = [];
      let vals = content[groupName]['links'].map((e) => Number(e.val));
      let maxMark = Math.max(...vals);
      console.log(maxMark);
      content[groupName]['links'].forEach((element) => {
        if (Number(element.val) === maxMark) {
          arr.push({
            value: Number(element.val),
            label: (
              <span className={style.sliderMark}>
                <span>{element.val + ' € et + :'}</span>
                <span>{element.rate + ' %'}</span>
              </span>
            ),
          });
        } else {
          arr.push({
            value: Number(element.val),
            label: (
              <span className={style.sliderMark}>
                <span>{element.val + ' € :'}</span>
                <span>{element.rate + ' %'}</span>
              </span>
            ),
          });
        }
      });
      setMarks(arr);
      setMaxVal(maxMark);
    }
  }, [content, groupName, setMarks]);

  const updateSaving = useCallback(
    (sliderValue) => {
      if (
        content &&
        groupName &&
        content &&
        content[groupName] &&
        content[groupName]['links'] &&
        Array.isArray(content[groupName]['links'])
      ) {
        try {
          let border = Math.max(
            ...content[groupName]['links']
              .filter((e) => Number(e.val) < sliderValue)
              .map((e) => Number(e.val))
          );
          let rate = Number(
            content[groupName]['links'].find((e) => Number(e.val) === border)
              .rate
          );
          if (content[groupName]['averageRate']) {
            let val = Math.round(
              ((Number(content[groupName]['averageRate']) - rate) *
                sliderValue) /
                100
            );
            if (!isNaN(val) && val !== undefined && val !== null) {
              setSaving(val);
            }
          }
        } catch (e) {}
      }
    },
    [content, groupName, setSaving]
  );

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => updateSaving(sliderVal), 100);
  }, [updateSaving, sliderVal]);

  const ValueLabelComponent = useCallback(
    (props) => {
      const { children } = props;
      if (
        children &&
        children.props &&
        children.props.style &&
        children.props.style.left &&
        children.props.style.left !== tooltipProp
      ) {
        setTooltipProp(children.props.style.left);
      }
      return <>{children}</>;
    },
    [setTooltipProp, tooltipProp]
  );

  const updateVal = useCallback(
    (e, val) => {
      let copy = Math.min(val, maxVal);
      setSliderVal(copy);
    },
    [maxVal, setSliderVal]
  );

  function calculateValue(value) {
    return 2 ** value;
  }

  useEffect(() => console.log(marks), [marks]);
  return (
    <InlineGroup name={groupName}>
      <div className={style.block}>
        <div className={style.content}>
          <div className={style.box}>
            <h5 className={style.ctaText}>
              <InlineTextarea name="cta-text" />
            </h5>
            <div className={style.grid}>
              <div className={style.row}>
                <h6 className={style.hint}>
                  Faites glisser le slider ou entrez la valeur de votre bien :
                </h6>
                <div className={style.slider}>
                  <div className={style.sliderInput}>
                    <div className={style.input} style={{ left: tooltipProp }}>
                      <TextField
                        variant="outlined"
                        inputProps={{ step: 1000, min: 0 }}
                        type="number"
                        value={sliderVal}
                        onChange={(e) => setSliderVal(e.target.value)}
                      />
                    </div>
                  </div>
                  {content && (
                    <Slider
                      value={sliderVal}
                      onChange={updateVal}
                      scale={calculateValue}
                      step={1000}
                      marks={marks}
                      valueLabelDisplay="on"
                      max={maxVal}
                      min={0}
                      ValueLabelComponent={ValueLabelComponent}
                    />
                  )}
                </div>
              </div>
              {saving > 0 ? (
                <div className={style.result}>
                  <div className={style.savingText}>
                    <div>
                      En vendant votre bien avec GeneralValor, vous économisez :
                    </div>
                    <div className={style.saving}>{saving}€</div>
                    <div>
                      de commissions par rapport à une agence classique*
                    </div>
                  </div>
                  <div className={style.buttonRow}>
                    <Button
                      variant="contained"
                      className={style.button}
                      size="large"
                      href="/deposer"
                    >
                      {' '}
                      <InlineTextarea name="buttonText" />
                    </Button>
                  </div>
                  <div className={style.legal}>
                    *
                    {content &&
                      groupName &&
                      content &&
                      content[groupName] &&
                      content[groupName]['averageRateText'] &&
                      content[groupName]['averageRateText']}
                  </div>
                </div>
              ) : (
                <div className={style.resultHidden}>
                  <div className={style.savingText}>
                    <div>
                      utilisez le calculateur pour estimer le montant de vos
                      économies, ou bien déposez votre annonce :{' '}
                    </div>
                    <div className={style.buttonRow}>
                      <Button
                        variant="contained"
                        className={style.button}
                        size="large"
                        href="/deposer"
                      >
                        {' '}
                        <InlineTextarea name="buttonText" />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </InlineGroup>
  );
};

export default Prices;
