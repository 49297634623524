import React, {useCallback, useEffect, useState} from "react"
import style from "./style.module.scss"
import useSettings from "../../tools/settings"
import {useScreenPlugin} from "tinacms"
import {ColorButton} from "../../tools/logout";
import 'draft-js/dist/Draft.css';
import TextEditor from "../../tools/textEditor"
import axios from "axios"
import {IconButton, Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {Mail as MailIcon, Phone as PhoneIcon, Edit as EditIcon} from "@material-ui/icons"
import config from "../../config.json"

function ContactSettings(settings){
  
  const [content, setContent] = useState();  
  const [initialized, setInitialized] = useState(null)

  useEffect(()=>{
      settings.get().then(data=>{
        if(data!==undefined){
          setContent(data);
        }else{
          setContent({});            
        }
        setInitialized(false);
      }).catch(e=>{
        console.error(e)
      });
    
  },[settings, setInitialized]);

  
  const updateText = useCallback((val)=>{

     
      setContent(old=>{
          return {...old, text: val}
      })
  },[setContent])

 


  const submit = useCallback(()=>{
    if(content.text){
      var el = document.createElement("body")
      el.innerHTML = content.text;
      if(el.firstChild.innerHTML){
        content.text = el.firstChild.innerHTML
      }
    }
    settings.save(content);
  },[content, settings]);

  return<div className={style.settingForm}>
    {content &&
      <>
      <div className={style.group}>
        <div className={style.label}>Texte</div>       
          <TextEditor content={(content && content.text)? content.text : undefined} setContent={updateText} 
          initialized={initialized} setInitialized={setInitialized}/>   
      </div>
     
      <div className={style.buttonRow}>
        <ColorButton onClick={submit}>Save</ColorButton>
      </div>
      </>
    }
  </div>
}

const ContactButton = () => {

  const settings = useSettings("contact");
  const footerPluging = {
    name: 'Paramètres de la fenêtre "Contact"',
    Component: ()=> ContactSettings(settings),
    Icon: ()=><EditIcon/>,
    layout: 'popup'
  }
  useScreenPlugin(footerPluging);
   
  const [content, setContent] = useState();  
  useEffect(()=>{
      axios.get(config.api.baseurl+'/api/settings/contact').then(data=>{
        if(data!==undefined){
          setContent(data.data);
        }else{
          setContent({});            
        }
      }).catch(e=>{
        console.error(e)
      });
    
  },[]);

  const [open ,setOpen] = useState(false);

  const toggleDialog = useCallback(() => {
    setOpen(old=>!old)
  },[setOpen]);
  return (
    <div className={style.block}>
    <Dialog  onClose={toggleDialog} open={open}>
        <DialogTitle>
            Une question ? Contactez le Général :
        </DialogTitle>
        <DialogContent>
          {content && content.text && 
            <div dangerouslySetInnerHTML={{__html : content.text}} />
          }
        </DialogContent>
    </Dialog>
   <div className={style.button}>
        <IconButton onClick={toggleDialog} className={style.icon}><MailIcon  className={style.icon}/>/<PhoneIcon/></IconButton>
    </div> 

</div>
  )}

export default ContactButton;