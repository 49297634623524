import React from "react"
import style from "./style.module.scss"
import { InlineGroup } from 'react-tinacms-inline'
import { InlineWysiwyg } from 'react-tinacms-editor'
import ReactMarkdown from 'react-markdown'

const WysiwigBlock = ({groupName, content})=>{
    
    const imageProps = {
        parse: (item) => item.media_url,
        uploadDir: ""
   }
  return (
    <InlineGroup name={groupName}>
      <div className={style.block}>
        <div className={style.content}>
            <p className={style.text}>
                <InlineWysiwyg name="text" format="markdown">
                <ReactMarkdown imageProps={imageProps} source={(content && content[groupName] && content[groupName]["text"])?content[groupName]["text"] : null } />
                </InlineWysiwyg>
            </p>
        </div>
      </div>
    </InlineGroup>
  )
}

export default WysiwigBlock