import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Sell from "./pages/Sell";
import Depose from "./pages/Depose";
import Buy from "./pages/Buy";
import About from "./pages/About";
import Mentions from "./pages/Mentions"

export default function useRouter(){
    return [
        {
            path: "/",
            exact: true,
            component: <Home />
        },
        {
            path: "/vendre",
            exact: true,
            component: <Sell />
        },
        {
            path: "/deposer",
            exact: true,
            component: <Depose />
        },
        {
            path: "/acheter",
            exact: true,
            component: <Buy />
        },
        {
            path: "/a-propos",
            exact: true,
            component: <About />
        },
        {
            path: "/mentions-legales",
            exact: true,
            component: <Mentions />
        },
        {
            path: "/admin",
            exact: true,
            component: <Auth />
        }
    ]
}