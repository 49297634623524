import React from "react"
import style from "./style.module.scss"
import { InlineGroup, InlineTextarea } from 'react-tinacms-inline'
import backgroundImg from "../../images/sell_background.jpg"
import {Button} from "@material-ui/core"

const HomeHero = ({groupName})=>{


    return (
      <InlineGroup name={groupName}>
        <div className={style.block}>
            <img src={backgroundImg} className={style.image} alt="" />
            <div className={style.content}>
              <div>
                    <h3 className={style.bold}><InlineTextarea name='title' /></h3>
                  
                    <h6><InlineTextarea name='subtitle' /></h6>
              </div>
              <div className={style.buttonRow}>
                <Button variant="contained" className={style.button} size="large" href="/deposer"> <InlineTextarea name='buttonText' /></Button>
              </div>
            </div>
        </div>
      </InlineGroup>
    )
}

export default HomeHero