import React, { useCallback, useEffect, useRef } from 'react';
import Quill from "quill"
import "quill/dist/quill.bubble.css"
import "quill/dist/quill.snow.css"

function TextEditor({content, setContent, initialized, setInitialized}) {

    const editorRef = useRef();
    const handleChange = useCallback(()=>{
        if(editorRef.current){
            setContent(editorRef.current.innerHTML)
        }
    },[editorRef, setContent]);

    useEffect(()=>{
    if(editorRef.current!==undefined && initialized===false){
        if(content!==undefined){
            editorRef.current.innerHTML = content;            
        }
        let quill = new Quill(editorRef.current, {
            theme: 'snow',
            modules: {
                toolbar:[
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],      
                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown      
                    [{ 'align': [] }],
                  
                    ['clean']                                         // remove formatting button
                ]
            },
        });
        quill.on("text-change", ()=>handleChange())
        setInitialized(true)
    }
    },[editorRef, content, setInitialized, initialized, handleChange])

    return <div style={{minHeight:"150px"}} ref={editorRef}>
      </div>

}

export default TextEditor;