import React, { useEffect } from 'react';
import Calculator from '../../comps/calculator';
import Header from '../../comps/header'
import { checkLoggedIn } from '../../tools/settings';
import { useCMS } from 'tinacms';
import { usePlugin, useForm } from 'tinacms'
import { InlineForm } from 'react-tinacms-inline'
import useSettings from "../../tools/settings"
import Footer from "../../comps/footer"
import StickyContact from "../../comps/sticky-contact"
import PageHero from "../../comps/page-hero"
import deal from "../../images/deal.jpg"

function Page() {
    const cms=useCMS();
    useEffect(()=>checkLoggedIn(cms),[cms]);

    const settings = useSettings(["sellHero","prices"])
    // 1. Define the form
    const FormOptions = {
      id:"sellpage",
      loadInitialValues() {
         let data = settings.get();
         console.log(data);
         return(data)
      },
      onSubmit: (content) => settings.save(content)
    }
  
    // 2. Pass the form as the second argument
     const [content, form] = useForm(FormOptions)
     // 3. Register the form as a plugin
     usePlugin(form)
    return <>
        <Header />
        <div className="page">
          <InlineForm form={form}>
            <PageHero content={content} groupName="sellHero" backgroundImage={deal}/>
            <Calculator content={content} groupName="prices"/>
          </InlineForm>
        </div>
        <StickyContact />
        <Footer />


    </>
}

export default Page;