import { Link } from "react-router-dom"
import React, {useState, useCallback} from "react"
import style from "./style.module.scss"
import logo from "../../images/general_bleu_saumon.svg"
import useMedia from 'use-media';
import {Drawer, Button} from '@material-ui/core';
import {Menu as MenuIcon} from "@material-ui/icons"

const Header = () => {
  const links=[
    {
      link:"/",
      title:" ACCUEIL"
    },
    {
      link:"/vendre",
      title: "VENDRE"
    },
    {
      link:"/acheter",
      title:"ACHETER"
    },
    {
      link:"/a-propos",
      title:" À PROPOS"
    },
 
  ];
  const [drawer,setDrawer] = useState(false);

  const isWide = useMedia({minWidth: style.mobileBreakpoint});
  const toggleDrawer = useCallback(() => {
    setDrawer(old=>!old)
  },[setDrawer]);

  return (
  <header>
    <div className={style.block}>
      <div className={style.navbar}>
        <Drawer anchor="right" onClose={toggleDrawer} open={drawer} >
          <div className={style.drawerMenu}>
              {links.map((element, index)=>(
                <Link
                  to={element.link}
                  key={index}
                >
                  <span>{element.title}</span>
                  <span className={style.divider}/>
                </Link>
              ))}
          </div>
        </Drawer>
        <Link
          to="/"
          className={style.link}
        >
          <img src={logo} className={style.logo} alt="logo"/>
        </Link>      
      <div className={style.content}>
        <h5 className={style.title}>
          <Link
              to="/"
              className={style.link}
            >
              <span className={style.blue}>General</span>
              <span className={style.salmon}>Valor</span>
          </Link>
        </h5>
        {isWide? 
        <div className={style.buttons}>
          {links.map((element, index)=>(
            <Link
              to={element.link}
              key={index}
              className= {style.headerLink}
            >
              <span>{element.title}</span>
            </Link>
          ))}
        </div>
        :<div className={style.mobileButton}>  
        <Button onClick={toggleDrawer}><MenuIcon/></Button>        
       
        </div>
        }
      </div>
      </div>
    </div>
  </header>
  )}

export default Header
